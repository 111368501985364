
class Interacao{
    constructor(id,cat,dt,loc,atId,rcId,sCat = ''){
        this.id = id;
        this.categoria = cat;
        this.subcategoria = sCat;
        this.data = dt;
        this.localizacao = loc;
        this.autorId = atId;
        this.receptorId = rcId;
        this.receptorTag = '';
    }

}

export default Interacao;