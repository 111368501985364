import React from 'react'
import './index.css'
import Modal from '../Modal'
import MyContainer from '../MyContainer'
import TitleOfGroup from '../TitleOfGroup'
import LeitorQR from '../LeitorQR'
import { Link } from 'react-router-dom'

class MnModal extends React.Component{
    render(){
        return(
            <Modal title="menu">
                <MyContainer>
                    <ul class="menu-modal">
                        <li><Link to="/" >Histórico</Link></li>
                        <li><Link to="/" >Configuração</Link></li>
                        <li><Link to="/" >Add Animal</Link></li>
                    </ul>
                </MyContainer>
            </Modal>
        )
    }
}

export default MnModal