import React from 'react'
import './index.css'
import MySection from '../MySection'
import MyContainer from '../MyContainer'
//import MyBtn from '../MyBtn'

import Header from '../Header'

import SheetDescription from '../SheetDescription'
import TitleOfGroup from '../TitleOfGroup'
import FireStore from '../../services/FireStore'
import Animal from '../../model/Animal';
//import MyDialog from '../MyDialog'

import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

import {Redirect} from 'react-router-dom'
//import Interacao from '../../model/Interacao';

import {UserPosition} from '../../services/UserPosition'

class PagInteracao extends React.Component{
    constructor( props){
        super( props)

        this.state = {
            usuario: this.props.usuario,
            firebase: this.props.firebase,

            store: new FireStore( this.props.firebase),
            logger: this.props.message,

            animal: new Animal(),
            isInvalid: false,
            showDialog: false,
            sending: false,

            loading: this.props.loading
        }
    }

    componentWillMount(){
        this.getAnimalInfo()
    }

    async getAnimalInfo(){
        let store = this.state.store
        //debugger
        this.state.loading( true)
        try{
            let tagId = window.location.hash.match(/tagid=([\w|\d]+)/)[1]
            let animal = Animal.buildAnimal( await store.getInfoOfAnimalByTagId( tagId))
            this.setState({ animal})
        } catch( error) {
            this.state.logger( 'erro', 'Falha em obter dados vinculados a essa tag')
            this.setState({ isInvalid: true})
        }finally{
            this.state.loading( false)
        }
    }

    toggleShowDialog(e){
        let flagAtualizada = ! this.state.showDialog
        this.setState({showDialog: flagAtualizada})
    }

    async getUserLocation(){
        const { latitude, longitude, timestamp, accuracy, error } = await UserPosition( true, 500, 10000)
        return {
            lat: latitude + ((Math.random() * 100).toFixed(0) * 0.001),
            lng: longitude + ((Math.random() * 100).toFixed(0) * 0.001)
        }
    }

    renderRedirectToHome( ){
        if( this.state.isInvalid ){
            return(<Redirect to={`/`} />)
        }
        return null
    }


    renderSlider(){
        let images = this.state.animal.fotos

        if( images.length){
            return(
                <Carousel className="display-foto-animal" showThumbs={false} showIndicators={false} dynamicHeight={true} showStatus={false}>
                    {
                        images.map( ( e, i) => {
                            return(
                                <div key={`carousel-img-${i}`} >
                                    <div style={{
                                    backgroundImage: `url(${e})`,
                                    backgroundSize: 'cover',
                                    overflow: 'hidden',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center',
                                    maxWidth: '250px'
                                    }} className="container-image-background-caroussel" >
                                        <span className="grow-height"></span>
                                    </div>
                                </div>
                            )
                        })
                    }
                </Carousel>
            )
        }
        return null
    }

    renderInterecationTable(){
        const interactions = this.state.animal.historicoInteracao

        if( interactions.length){
            return(<ul className="table-historic">
                {
                    interactions.map( ( e, i) => {
                        let classIcons ={
                            'atenção': 'fa-hand-holding-heart',
                            'alimentação': 'fa-drumstick-bite',
                            medicamento: 'fa-syringe'
                        }
                        return(<li key={'int_list_' + i}>
                            <span><i className={'fas ' + classIcons[e.categoria.toLowerCase()]}></i>{`${e.categoria} ${e.sub ? ' - ' + e.sub : ''}`}</span><span>{`${new Date(e.data).toLocaleDateString('pt-BR',{ day: 'numeric', month: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric'})}`}</span>
                        </li>)
                    })
                }
            </ul>)
        }
        
        return null
    }

    render(){
        let a = this.state.animal
        return(
            <React.Fragment>
                <Header isNotHome={true} />
                <div>
                    {this.renderRedirectToHome()}
                    <MySection distTop="60px + 3vh" > {/*distancia top igual a altura do header*/}
                        <div className="no-align-item-center">
                            <MyContainer direction='row'>
                                    <SheetDescription infos={[a.nome,a.raca,a.idade,a.porte]} />
                                    {/* <SetAndShowImage classes={`display-foto-animal`} listImages={a.fotos} setImages={null} width="45vw" /> */}
                                    {this.renderSlider()}
                            </MyContainer>
                        </div>
                    </MySection> 
                    <MySection distTop="40vh">
                        <MyContainer width="80vw" maxW="300px" >
                            <TitleOfGroup color="#005999" contentText="Historico Interações" />
                            {this.renderInterecationTable()}
                        </MyContainer>
                    </MySection>
                </div>
            </React.Fragment>
        )
    }
}

export default PagInteracao