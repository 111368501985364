
class FireAuth{
    constructor( firebase){
        this.fb = firebase.firebase_;
    }

    
    loginFacebook(callback){
        // cria uma nova instancia do provedor de login do Facebook.
        var provider = new this.fb.auth.FacebookAuthProvider();

        return this.fb.auth().signInWithPopup(provider).then(resposta => {
            callback( resposta)
        }).catch(erro => {
            console.log('erro', erro);
        })
    }

    loginGoogle(callback) {
        var provider = new this.fb.auth.GoogleAuthProvider();
        
        return this.fb.auth().signInWithPopup(provider).then(resposta => {
            callback( resposta);
        }).catch(erro => {
            console.log('erro', erro);
        })
    }
}

export default FireAuth;