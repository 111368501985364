import Repository from '../infrastructure/Repository.js'
const repository = new Repository();

class Cachorreiro{
    constructor(id, uN,mE){              //informações requeridas sempre
        this.id = id;                    //provavelment um hash do banco
        this.perfil = null;
        this.userName = uN;
        this.mainTelefone = '';
        this.numTelefones = [];
        this.mainEmail = mE;
        this.emails = [mE];

        this.vinculos = {
            conhecidos:[],
            amigos:[],
            padrinhos:[],
            guardioes:[],
            tutores:[],
        };
        this.historicoInteracao = [];
    }

    save( callback){
        repository.save( this, callback);
    }
    static get( sucesso, falha){
        repository.get( dt => {
            let ca = new Cachorreiro(dt.id, dt.userName, dt.mainEmail);

            Object.keys( ca).forEach( e => {
                ca[e] = dt[e] || null;
            });

            //retorno
            sucesso( ca);
        }, falha);
    }
}

export default Cachorreiro;