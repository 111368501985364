import React from 'react'
import Modal from '../Modal'
import MyContainer from '../MyContainer'
import TitleOfGroup from '../TitleOfGroup'
import QrReader from 'react-qr-reader'
import {Redirect} from 'react-router-dom'
import FireStore from '../../services/FireStore'

import './index.css'

class QrModal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            usuario: this.props.usuario,
            firebase: this.props.firebase,
            result: '______________',
            //id da tag, lido no qr
            tagid: '',
            //onde seguira depois do modal
            destino: window.location.hash.match(/dest=(\w+)/)[1],
            //status da tag, consultado no banco
            status: null
        }
    }
    handleScan = rawData => {
        if (rawData) {
            let data = 'QR inadequado'
            try{
                data = rawData.match(/([\w|\d]+)/)[1]
                this.setState({
                    result: data,
                })
                setTimeout( () => this.setState({tagid: data}), 1000)
            }catch( er){
                console.log(er)
            }
            finally{
                this.setState({
                    result: data,
                })
            }
        }
    }
    handleError = err => {
        console.error(err)
    }

    renderResult = () => {
        return (<p className="text-result-qr">{this.state.result}</p>)
    }
    renderRedirect = () => {
        let id = this.state.tagid
        let page = this.state.destino
        let statusTag = this.state.status
        if (this.state.tagid) {
            return(<Redirect to={`/${page}?tagid=${id}`} />)
        }
    }
    consultaStatusTag = async (id) => {
        //obj de acesso ao banco
        let store = new FireStore(this.state.firebase);

        await store.consultar('tags',id, resp => this.state.status = resp.data.status)
    }

    render() {
        return (
            <Modal title="scanner">
                <MyContainer>
                    <TitleOfGroup color="#005999" contentText="Aponte a camera para o QR code na coleira" />
                    <div className="qr-reader-container" >
                        <QrReader
                            delay={300}
                            onError={this.handleError}
                            onScan={this.handleScan}
                        />
                    </div>
                    {this.renderResult()}
                    {this.renderRedirect()}
                </MyContainer>
            </Modal>
        )
    }
}

export default QrModal