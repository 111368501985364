import React from 'react'
import './index.css'

export default function MySection( props){
    const estilo = {}
    props.distTop && (estilo.top = `calc( ${props.distTop} )`)
    props.distBottom && (estilo.bottom = `calc( ${props.distBottom} )`)
    return(
        <section className={`my-section ${props.className || ''}`} style={estilo}>
            <div>
                {props.children}
            </div>
        </section>
    )
}