import React from 'react'
import Image from '../Image'
import Img from './img/logoAujude_vermelho_small.png'
import MySection from '../MySection'
import MyContainer from '../MyContainer'
import MyBtn from '../MyBtn'
import TitleOfGroup from '../TitleOfGroup'

class NovoUsuario extends React.Component{
    render(){
        return(
            <div>
                <MySection distTop="25vh - 109px" >
                    <Image arquivo={Img} width="260px" height="109px" />
                </MySection >
                <MySection distTop="50vh" >
                    <MyContainer>
                        <TitleOfGroup color="#005999" contentText="Login com:" />
                        <MyBtn size="full" myStyle="line" color="#FF4A3A" onClick={this.props.login.google} >
                            <i className="fa fa-google"></i>Google
                        </MyBtn>   
                        {/* <MyBtn size="full" myStyle="line" color="#FF4A3A" onClick={this.props.login.facebook}>
                            <i className="fa fa-facebook"></i>Facebook
                        </MyBtn>                    */}
                    </MyContainer>
                </MySection>
            </div>
        )
    }
}

export default NovoUsuario