import React from 'react'
import './index.css'

export default function InputSelect( props){
    let name = props.name || props.options[0].value
    let isRequired = props.required || false
    let onChange = props.onChange || null
    return(
        <div className="field-select" >
            <select name={name} required={ isRequired} onChange={ onChange} defaultValue={props.options[0].value} >
                {props.options.map( (e,i) =>{
                    if( i === 0)
                        return <option key={`${name}-op-${i}`} value={e.value} disabled >{e.text}</option>
                    return <option key={`${name}-op-${i}`} value={e.value} >{e.text}</option>
                })}
            </select>
        </div>
    )
}