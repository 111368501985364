import React from 'react'
import './index.css'
import SheetDescription from  '../SheetDescription'
import CardImage from '../CardImage'

export default function CardInteracao( props){
    return(
        <div className="card-interacao">
            <CardImage imgUrl={props.imgUrl} width="70px" height="70px" />
            <SheetDescription infos={[{text: 'rexPastor'},{text: '18/05/2019'},{text: 'Carinho'}]} />
        </div>
    )
}