import firebase from 'firebase/app';
import 'firebase/auth';        // for authentication
import 'firebase/storage';     // for storage
import 'firebase/firestore';   // for cloud firestore

const config = {
    apiKey: "AIzaSyB-_2aS1mxc8HFN36Dbm0oMWeBb7WNkLU0",
    authDomain: "teste-2o19.firebaseapp.com",
    databaseURL: "https://teste-2o19.firebaseio.com",
    projectId: "teste-2o19",
    storageBucket: "teste-2o19.appspot.com",
    messagingSenderId: "799581959928",
    appId: "1:799581959928:web:14ccf45ec9e3bffb"
  };

export const firebaseImpl = firebase.initializeApp(config);