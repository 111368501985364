import React from 'react'
import './index.css'

import MySection from '../MySection'
import MyContainer from '../MyContainer'
import MyForm from '../MyForm'
import InputText from '../InputText'
import MyBtn from '../MyBtn'
import InputSelect from '../InputSelect'
import SetAndShowImage from '../SetAndShowImage'
import Header from '../Header'

import {Redirect} from 'react-router-dom'

import nomesAnimais from './nomesAnimais.json'
import Animal from '../../model/Animal.js'

import FireStore from '../../services/FireStore'
import InputCheck from '../InputCheck';

class PagCadastro extends React.Component{
    constructor( props){
        super( props)
        this.state = {
            usuario: this.props.usuario,
            firebase: this.props.firebase,
            logger: this.props.message,
            privilegios: this.props.usuario.perfil == 'admin' ? 0 : 1,
            store: new FireStore(this.props.firebase),
            tagId: '',
            animal: new Animal(),
            isInvalid: false,
            sending: false
        }
    }

    setNomeRand(e){
        e.stopPropagation()
        let animal = this.state.animal
        let targetID = e.target.dataset.alvo
        let length = nomesAnimais.length
        let indexSorteado = Math.floor(Math.random() * length)
        animal.nome = nomesAnimais[ indexSorteado]
        this.setState({animal })
        document.getElementById(targetID).dispatchEvent( new MouseEvent( 'focus',{}))
    }
    allFieldsFill(){
        let result = true
        let fields = Object.assign( [], document.querySelectorAll( '*[required]'))
        const fieldIsVoid = ( elem) => elem.value === elem.firstElementChild.value

        fields.forEach( e => {
            if(e.tagName === 'SELECT'){
                if( fieldIsVoid(e)) return false
            } else if( !e.value) {
                result = false
            }
        })
        return result && this.state.animal.fotos.length
    }
    setAnimal(e){
        let animal = this.state.animal
        let key = e.target.name 
        let val = e.target.value

        animal[key] = val
        this.setState({animal})
    }
    setTutor(e){
        let animal = this.state.animal
        let val = e.target.checked
        let userId = this.state.usuario.id

        if( val){
            animal.vinculos.tutores.push( userId)
        } else {
            animal.vinculos.tutores = []
        }

        this.setState({animal })
    }

    save(){
        this.props.loading(true)
        let inProgess = this.state.sending
        let store = this.state.store
        let userId = this.state.usuario.id
        let tagId = this.state.tagId
        if( this.allFieldsFill() && !inProgess){
            this.setState({ sending: true});
            //debugger
            let animal = this.state.animal
            animal.tagId = tagId
            store.addAnimal(userId, tagId, animal, this.successToSend.bind(this), this.failToSend.bind(this) )  
        } else {
            this.props.loading(false)
            this.state.logger( 'erro', 'Campos incompletos!')
        }
    }
    successToSend(){
        this.props.loading(false)
        this.setState({ isInvalid: true})
        this.state.logger( 'sucesso', 'sucesso no cadastro!')
    }
    failToSend(){
        this.props.loading(false)
        this.setState({ sending: false});
        this.state.logger( 'info', 'Falha ao cadastrar, tente mais tarde')
    }

    //impede continuar nessa pagina sem uma tag id
    hasTagId(){
        let store = this.state.store
        const chegaStatusTag = async ( id, isPositive) => {
            await store.consultar('tags',id, snapshot => {
                if( snapshot.exists){
                    let dataTag = snapshot.data()           //não é um objeto tag completo
                    if( dataTag.status == 'utilizada'){
                        this.setState({ isInvalid: true})
                        this.state.logger( 'info', 'Essa tag já foi utilizada')
                    } else {
                        isPositive({ tagId: id})
                    }
                } else {
                    this.setState({ isInvalid: true})
                    this.state.logger( 'info', 'Tag não localizada')
                }
            })
        }
        if(! this.state.tagId){
            try{
                let id = window.location.hash.match(/tagid=([\w|\d]+)/)[1]
                chegaStatusTag( id, this.setState.bind(this))
                return null
            }catch( er){
                this.setState({ isInvalid: true})
                this.state.logger( 'erro', 'Erro na checagem da tag')
            }
        }
    }
    
    componentWillMount(){
        this.hasTagId()
    }

    setImages( images){
        let animal = this.state.animal
        animal.fotos = images
        this.setState( { animal})
    }

    renderRedirectToHome( ){
        if( this.state.isInvalid ){
            return(<Redirect to={`/`} />)
        }
        return null
    }
    renderInputCheck(){
        let isChanged = this.state.privilegios === 0 ? true : false
        if( isChanged){
            return(
                <InputCheck hasChecked={this.state.animal.vinculos.tutores.length} onChange={this.setTutor.bind(this)} id="tutor" type="checkbox" labelText="Esse animal é meu?" />
            )
        } else {
            return(
                <InputCheck hasChecked={true} readOnly={true} onChange={this.setTutor.bind(this)} id="tutor" type="checkbox" labelText="Esse animal é meu?" />
            ) 
        }
    }

    render(){
        return (
            <React.Fragment>
                <Header isNotHome={true} />
                <div className="pg-cadastro">
                    {this.renderRedirectToHome()}
                    <MySection className="no-abs mt-60" distTop="60px" > {/*distancia top igual a altura do header*/}
                        <SetAndShowImage listImages={this.state.animal.fotos} setImages={this.setImages.bind(this)} maxW="80%" classes="coroussel-cadastro-animal" />
                    </MySection> 

                    <MySection className="no-abs" distTop="43vh">
                        <MyContainer zIndex="initial">
                            <MyForm>
                                {this.renderInputCheck()}
                                <div className="group-input-name w-100">
                                    <div className="btn-rand">
                                        <span><i className="far fa-question-circle"></i> </span>
                                        <span onClick={this.setNomeRand.bind(this)}><i data-alvo="nome" className="fas fa-sync-alt"></i></span>
                                    </div>
                                    <InputText onChange={this.setAnimal.bind(this)} defaultValue={this.state.animal.nome} required id="nome" name="nome" labelText="Nome:" />
                                </div>
                                <InputText onChange={this.setAnimal.bind(this)} required id="raca" name="raca" labelText="Raça aparente:" />
                                <InputSelect onChange={this.setAnimal.bind(this)} required options={[{value: 'sexo', text: 'Sexo:'},{value: 'macho', text: 'Macho'},{value: 'femea', text: 'Femea'}]} />
                                <InputSelect onChange={this.setAnimal.bind(this)} required options={[{value: 'idade', text: 'Idade aparente:'},{value: 'filhote', text: 'Filhote  até 3 anos'},{value: 'jovem', text: 'Jovem  de 3 até 7 anos'},{value: 'adulto', text: 'Adulto de 7 até 11 anos'},{value: 'idoso', text: 'Idoso  mais de 11 anos'}]} />
                                <InputSelect onChange={this.setAnimal.bind(this)} required options={[{value: 'porte', text: 'Porte:'},{value: 'mini', text: 'Mini até 6kg'},{value: 'pequeno', text: 'Pequeno de 6 até 12kg'},{value: 'medio', text: 'Médio de 12 até 22kg'},{value: 'grande', text: 'Grande acima de 22kg'}]} />
                            </MyForm>
                            <div style={{marginTop: '30px'}}>
                                <MyBtn onClick={this.save.bind(this)} size="full" myStyle="solid" color="#FF4A3A">
                                    SALVAR
                                </MyBtn> 
                            </div>
                        </MyContainer>
                    </MySection>
                </div>
            </React.Fragment>
        )
    }
}

export default PagCadastro