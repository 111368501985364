import React from 'react'
import Image from '../Image'

export default function CardImage( props){
    return(
        <div>
            <a href="\" >
                <Image arquivo={props.imgUrl} width={props.width} height={props.height} />
            </a>
        </div>
    )
}