import React from 'react'
import './index.css'

export default function NewModal ( props){

    return(
        <div className="new-modal" onClick={props.onClick}>
            <div onClick={(e) => e.stopPropagation()}>
                <div className="modal-header">
                    <div></div>
                    <div className="modal-title">
                        <span>{props.title}</span>
                    </div>
                    <div className="modal-close">
                        <a onClick={(e) => {e.preventDefault(); props.onClick() }}>
                            <i className="fas fa-times"></i>
                        </a>
                    </div>
                </div>
                <div className="modal-content">
                    <div>
                        {props.children}
                    </div>
                </div>
            </div>
        </div>
    )
}