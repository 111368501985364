const buildObjResult = ({coords, timestamp}) => {
  return {
    latitude: coords.latitude,
    longitude: coords.longitude,
    accuracy: coords.accuracy,
    timestamp,
  }
};

export async function UserPosition( enableHighAccuaracy, timeout, maximumAge){

  const settings = {
    enableHighAccuaracy,
    timeout,
    maximumAge
  }
  const geo = navigator.geolocation;
  if (! geo) {
    throw 'Geolocation is not supported'
  }

  try{
    let result = await new Promise( function( resolve, reject){
      geo.getCurrentPosition(resolve, reject, settings)
    });

    return buildObjResult( result)
  }catch( error){
    throw 'localização não recuperada'
  }
  
};