import React from 'react'
import './index.css'
import MySection from '../MySection'
import MyContainer from '../MyContainer'
import MyBtn from '../MyBtn'
import Header from '../Header'

import SheetDescription from '../SheetDescription'
import TitleOfGroup from '../TitleOfGroup'
import FireStore from '../../services/FireStore';
import Animal from '../../model/Animal';
import MyDialog from '../MyDialog'

import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

import {Redirect} from 'react-router-dom'
import Interacao from '../../model/Interacao';

import {UserPosition} from '../../services/UserPosition'

class PagInteracao extends React.Component{
    constructor( props){
        super( props)

        this.state = {
            usuario: this.props.usuario,
            firebase: this.props.firebase,

            store: new FireStore( this.props.firebase),
            logger: this.props.message,

            animal: new Animal(),
            isInvalid: false,
            showDialog: false,
            sending: false,

            currentInteraction: new Interacao()
        }
    }

    componentWillMount(){
        this.getAnimalInfo()
    }

    async getAnimalInfo(){
        let store = this.state.store
        let tagId = window.location.hash.match(/tagid=([\w|\d]+)/)[1]

        try{
            let animal = Animal.buildAnimal( await store.getInfoOfAnimalByTagId( tagId))
            this.setState({ animal})
        } catch( error) {
            this.state.logger( 'erro', 'Falha em obter dados vinculados a essa tag')
            this.setState({ isInvalid: true})
        }
    }

    toggleShowDialog(e){
        let flagAtualizada = ! this.state.showDialog
        this.setState({showDialog: flagAtualizada})
    }
    setCategoryAndSubCategory(e, cat, sub = ''){
        let interaction = this.state.currentInteraction
        interaction.categoria = cat
        interaction.subcategoria = sub

        this.save( interaction)
    }

    completInfoInteraction(interaction){
        interaction.data = new Date().getTime()
        interaction.autorId = this.state.usuario.id
        interaction.receptorId = this.state.animal.id
        interaction.receptorTag = this.state.animal.tagId
        return interaction
    }
    async getUserLocation(){
        const { latitude, longitude, timestamp, accuracy, error } = await UserPosition( true, 500, 10000)
        // return {
        //     lat: latitude + ((Math.random() * 100).toFixed(0) * 0.001),
        //     lng: longitude + ((Math.random() * 100).toFixed(0) * 0.001)
        // }
        return {
            lat: latitude,
            lng: longitude
        }
    }

    async save( interactionToComplete){
        this.props.loading(true)
        let inProgess = this.state.sending
        let store = this.state.store
        let interaction = this.completInfoInteraction( interactionToComplete)
        interaction.localizacao = await this.getUserLocation()
  
        if( !inProgess){
            this.setState({ sending: true});
            //debugger
            store.addInteraction(interaction, this.successToSend.bind(this), this.failToSend.bind(this) )  
        } else{
            this.props.loading(false)
        }
    }
    successToSend(){
        this.props.loading(false)
        this.props.reloadHistoric()
        this.setState({ isInvalid: true})
        this.state.logger( 'sucesso', 'sucesso no cadastro!')
    }
    failToSend(){
        this.props.loading(false)
        this.setState({ sending: false});
        this.state.logger( 'info', 'Falha ao cadastrar, tente mais tarde')
    }
    
    renderRedirectToHome( ){
        if( this.state.isInvalid ){
            return(<Redirect to={`/`} />)
        }
        return null
    }

    renderDialog(){
        if( this.state.showDialog){
            return(
                <MyDialog closeFunction={this.toggleShowDialog.bind(this)}>
                    <div onClick={ (e) => this.setCategoryAndSubCategory(e,'medicamento', 'vacina antirrábica')} >Vacina antirrábica</div>
                    <div onClick={ (e) => this.setCategoryAndSubCategory(e,'medicamento', 'vermifugo')} >Vermifugo</div>
                    <div onClick={ (e) => this.setCategoryAndSubCategory(e,'medicamento', 'antibiótico')} >Antibiótico</div>
                    <div onClick={ (e) => this.setCategoryAndSubCategory(e,'medicamento', 'outros')} >Outros</div>
                </MyDialog>
            )
        }
        return null
    }

    renderSlider(){
        let images = this.state.animal.fotos

        if( images.length){
            return(
                <Carousel className="display-foto-animal" showThumbs={false} showIndicators={false} dynamicHeight={true} showStatus={false}>
                    {
                        images.map( ( e, i) => {
                            return(
                                <div key={`carousel-img-${i}`} >
                                    <div style={{
                                    backgroundImage: `url(${e})`,
                                    backgroundSize: 'cover',
                                    overflow: 'hidden',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center',
                                    maxWidth: '250px'
                                    }} className="container-image-background-caroussel" >
                                        <span className="grow-height"></span>
                                    </div>
                                </div>
                            )
                        })
                    }
                </Carousel>
            )
        }
        return null
    }

    render(){
        let a = this.state.animal 
        return(
            <React.Fragment>
                <Header isNotHome={true} />
                <div>
                    {this.renderRedirectToHome()}
                    <MySection distTop="60px + 3vh" > {/*distancia top igual a altura do header*/}
                        <div className="no-align-item-center">
                            <MyContainer direction='row'>
                                    <SheetDescription infos={[a.nome,a.raca,a.idade,a.porte]} />
                                    {/* <SetAndShowImage classes={`display-foto-animal`} listImages={a.fotos} setImages={null} width="45vw" /> */}
                                    {this.renderSlider()}
                            </MyContainer>
                        </div>
                    </MySection> 

                    <MySection distTop="40vh">
                        <MyContainer >
                            <TitleOfGroup color="#005999" contentText="O que foi feito?" />
                            <MyBtn size="full" myStyle="line" color="#FF4A3A" onClick={ (e) => this.setCategoryAndSubCategory( e, 'atenção','')}>
                                <i className="fas fa-hand-holding-heart"></i>Carinho
                            </MyBtn>  
                            <MyBtn size="full" myStyle="line" color="#FF4A3A" onClick={ (e) => this.setCategoryAndSubCategory( e, 'alimentação','')}>
                            <i className="fas fa-drumstick-bite"></i>Alimento
                            </MyBtn>
                            <MyBtn size="full" myStyle="line" color="#FF4A3A" onClick={this.toggleShowDialog.bind(this)}>
                            <i className="fas fa-syringe"></i>Remédio
                            </MyBtn> 
                        </MyContainer>
                    </MySection>
                    {this.renderDialog()}
                </div>
            </React.Fragment>
        )
    }
}

export default PagInteracao