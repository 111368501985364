import React from 'react'
import './index.css'

//my components
import MyContainer from '../MyContainer'
//import Display from '../Display'
//import CardImageAdd from '../CardImageAdd'
import NewModal from '../NewModal'
import TitleOfGroup from '../TitleOfGroup'
import MyBtn from '../MyBtn'

//componentes de terceiros
import Camera, { FACING_MODES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

class SetAndShowImage extends React.Component {
    constructor( props){
        super( props)

        this.state = {
            showCamera: false,
            cameraMode: Object.keys(FACING_MODES),
            forceDesableFacingMode: false,
            errorCapturing: false
        }
    }
    onTakePhoto (dataUri) {
        let newListFotos = this.props.listImages
        newListFotos.push( dataUri)
        this.props.setImages( newListFotos)
        //this.setState({ listFotos: newListFotos})
    }
    showModalCamera (){
        this.setState( { showCamera: true})
    }
    hideModalCamera (){
        this.setState( { showCamera: false})
    }
    changeCameraMode (e){
        e.preventDefault()
        const self = this
        let { cameraMode } = this.state
        let temp = cameraMode.shift()
        cameraMode.push(temp)
        this.setState({ cameraMode }, () => self.forceUpdate())
    }
    async setErrorCapturing (){
        let videoDeviceAuthorized
        let browserNotHasResource = false
        if( window.navigator && window.navigator.mediaDevices && window.navigator.mediaDevices.enumerateDevices){
            videoDeviceAuthorized = window.navigator && window.navigator.mediaDevices && window.navigator.mediaDevices.enumerateDevices && 
            (await window.navigator.mediaDevices.enumerateDevices()
            .then(e => e.filter(r => r.kind == 'videoinput' && r.label))[0])
        } else {
            browserNotHasResource = true
        }
        if(!this.state.forceDesableFacingMode)
            this.setState({ forceDesableFacingMode: true })
        if(!this.state.errorCapturing && (videoDeviceAuthorized || browserNotHasResource))
            this.setState({ errorCapturing: true })
        if(!videoDeviceAuthorized && !browserNotHasResource)
            navigator.mediaDevices.getUserMedia({video: true})
    }

    async handdlerUploadImageFromFile (e){
        debugger
        const toDataURL = url => fetch(url)
        .then(response => response.blob())
        .then(blob => new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.onloadend = () => resolve(reader.result)
            reader.onerror = reject
            reader.readAsDataURL(blob)
        }))

        const listToResolve = [...e.target.files].map(img => toDataURL(URL.createObjectURL(img)))

        const listImgsBase64 = await Promise.all(listToResolve)

        this.props.setImages( listImgsBase64)
        this.hideModalCamera()
    }

    renderModalCamera (){
        if( this.state.showCamera){
            let propsConditional ={}
            !this.state.forceDesableFacingMode && navigator && navigator.mediaDevices && navigator.mediaDevices.getSupportedConstraints().facingMode && (propsConditional.idealFacingMode = FACING_MODES[this.state.cameraMode[1]])
            return (
                <NewModal onClick={this.hideModalCamera.bind(this)} title="camera">
                    <MyContainer>
                        {this.state.errorCapturing ? (
                            <>
                                <TitleOfGroup color="#005999" contentText={<>Não possivel acesssar a camera <br /> clique no botão abaixo para selecionar fotos da galeria</>} />
                                <input style={{padding: '20px 0'}} type="file" accept="image/png, image/jpeg" multiple onChange={this.handdlerUploadImageFromFile.bind(this)} />
                            </>
                        ) : (
                            <>
                                <TitleOfGroup color="#005999" contentText="Capture imagens do animal" />
                                <div className="camera-container" >
                                    <Camera
                                        onTakePhoto = { (dataUri) => { this.onTakePhoto(dataUri); } }
                                        onCameraError = { this.setErrorCapturing.bind(this) }
                                        idealResolution={{
                                            width: 800,
                                            height: 600
                                        }}
                                        {...propsConditional}
                                        isImageMirror = {false}
                                        isDisplayStartCameraError= {false}
                                    />
                                    {propsConditional.idealFacingMode && <a href="#" onClick={this.changeCameraMode.bind(this)}>Reverter Camera</a>}
                                </div>
                            </>
                        )}
                    </MyContainer>
                </NewModal>
            )
        }
        return (
            null
        )
    }
    renderSlider (){
        let images = this.props.listImages

        if( images.length){
            return(
                <Carousel showThumbs={false} showIndicators={false} dynamicHeight={true} showStatus={false}>
                    {
                        images.map( ( e, i) => {
                            return(
                                <div key={`carousel-img-${i}`} >
                                    <div style={{
                                    backgroundImage: `url(${e})`,
                                    backgroundSize: 'cover',
                                    overflow: 'hidden',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center',
                                    maxWidth: '250px'
                                    }} className="container-image-background-caroussel" >
                                        <span className="grow-height"></span>
                                    </div>
                                    {/* <img src={e} /> */}
                                    <p className="legend">
                                        <span onClick={this.showModalCamera.bind(this)} ><i className="fas fa-plus"></i></span>
                                        <span onClick={() => this.deletePhoto(i)}><i className="fas fa-trash-alt"></i></span>
                                    </p>
                                </div>
                            )
                        })
                    }
                </Carousel>
            )
        }
        return(
            <Carousel showThumbs={false} showIndicators={false} dynamicHeight={true} showStatus={false}>
                <div onClick={this.showModalCamera.bind(this)} >
                    <div className="banner-inicial">
                        <i className="fas fa-plus"></i>
                        <i className="fas fa-trash-alt"></i>
                        <i className="fas fa-camera"></i>
                        <span className="message">Tirar Foto</span>
                    </div>
                </div>
            </Carousel>
        )
    }
    deletePhoto ( index){
        let newListFotos = this.props.listImages
        newListFotos.splice( index, 1)
        this.props.setImages( newListFotos)
        //this.setState({ listFotos: newListFotos})
    }
    render(){
        return(
            <MyContainer classes={this.props.classes} direction='row' maxW={this.props.maxW} width={this.props.width} >
                { this.renderModalCamera()}
                { this.renderSlider()}
            </MyContainer>
        )
    }
}

export default SetAndShowImage