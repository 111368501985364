import React from 'react'
import './index.css'
import Image from '../Image'
import Img from './img/logoAujude_vermelho_small.png'
import MyDialog from '../MyDialog'
import '../../../node_modules/font-awesome/css/font-awesome.min.css'
import { Link} from 'react-router-dom'

class Header extends React.Component{
    constructor( props){
        super( props)

        this.state ={
            isNotHome: this.props.isNotHome,
            showDialog: false,
            
        }
    }

    toggleShowDialog(e){
        let flagAtualizada = ! this.state.showDialog
        this.setState({showDialog: flagAtualizada})
    }

    renderHistoryBtn(){
        {
            if( this.props.historicDisplayFunction){
                return (
                    <a href="#" onClick={(e) => {
                            let flagAtualizada = ! this.state.showDialog
                            this.setState({showDialog: flagAtualizada})
                            this.props.historicDisplayFunction(e)
                        }}>
                        Histórico
                    </a>
                )
            } else {
                return(
                    <Link to="/historico" >
                        Histórico
                    </Link>
                )
            }
        }
    }

    logout(e){
        e.preventDefault()
        localStorage.clear()
        window.location.reload()
    }

    renderDialog(){
        if( this.state.showDialog){
            return(
                <MyDialog classes="main-menu-app" closeFunction={this.toggleShowDialog.bind(this)}>
                    { this.renderHistoryBtn()}
                    {/* <Link to="/">
                        Configuração
                    </Link> */}
                    <a href="#" onClick={this.logout}>Sair</a>
                    <a href="/tag-generator">Gerar tags</a>
                </MyDialog>
            )
        }
        return null
    }

    reloadPage(e){
        e.preventDefault()
        window.location.reload()
    }

    render(){
        let { isNotHome} = this.state

        return(
            <header className="my-header">
                <nav>
                    <div className="icon-return">{
                        isNotHome ? (
                            <Link to="/">
                                <i className="fas fa-chevron-left"></i>
                            </Link>
                        ) : (
                            <a href="#" onClick={this.reloadPage}>
                                <i className="fas fa-sync-alt"></i>
                            </a>
                        )
                        }</div>
                    <div className="nav-logo">
                        <Link to="/">
                            <Image arquivo={Img} width="140px" height="55px" />
                        </Link>
                    </div>
                    <div className="nav-menu" onClick={this.toggleShowDialog.bind(this)}>
                        <i className="fa fa-bars" href="#"></i>
                    </div>
                </nav>
                {this.renderDialog()}
            </header>
        )
    }
}

export default Header
