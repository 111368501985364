import React from 'react'
import MySection from '../MySection'
import MyContainer from '../MyContainer'
import MyForm from '../MyForm'
import InputText from '../InputText'
import MyBtn from '../MyBtn'
import InputSelect from '../InputSelect'
import TitleOfGroup from '../TitleOfGroup'

class PagEditProfile extends React.Component{

    render(){
        return (
            <div>
               
                <MySection distTop="15vh">
                    <MyContainer >
                        <TitleOfGroup color="#005999" contentText="Edit Perfil" />
                        <MyForm>
                            <InputText id="username" name="username" labelText="Username:" />
                            <InputText id="email" name="email" labelText="E-mail:" />
                            <div className="w-100" style={{marginTop: '15px',textAlign: 'end'}}>
                                <MyBtn size="small" myStyle="solid" color="#005999">
                                    <i className="fas fa-plus"></i>
                                </MyBtn>                            
                            </div>
                        </MyForm>
                        <div style={{marginTop: '30px'}}>
                            <MyBtn size="full" myStyle="solid" color="#FF4A3A">
                                SALVAR
                            </MyBtn> 
                        </div>
                    </MyContainer>
                </MySection>
            </div>
        )
    }
}

export default PagEditProfile