import React from 'react'
import MySection from '../MySection'
import MyContainer from '../MyContainer'
import MyBtn from '../MyBtn'
import {Redirect, Link} from 'react-router-dom'
import MyMap from '../MyMap'
import Header from '../Header'
import MyDialog from '../MyDialog'
import './index.css'

class Home extends React.Component{
    constructor( props){
        super( props)

        this.state = {
            usuario: this.props.usuario,
            firebase: this.props.firebase,

            logger: this.props.message,

            
            redirect: false,
            
            show: props.show,

            newCenter: null
        }
    }

    setRedirect = (e, lab) => {
        e.preventDefault()
        this.setState({
          redirect: lab
        })
    }

    toggleHistoric(e){
        e.preventDefault()
        e.stopPropagation()
        let newValue = this.state.show === 'historic' ? null : 'historic'
        this.setState({
            show: newValue
        })
    }

    renderRedirect = () => {
        if (this.state.redirect) {
          return <Redirect to={`/qrmodal?dest=${this.state.redirect}`} />
        }
      }

    renderDialog(){
        const {historicoInteracao} = this.state.usuario
        const show = this.state.show

        if( historicoInteracao.length && ( show === 'historic') ){
            return(
                <MyDialog classes="historic-dialog" closeFunction={this.toggleHistoric.bind(this)}>
                    <ul className="table-historic">
                        {
                            historicoInteracao.map( ( e, i) => {
                                let classIcons ={
                                    'atenção': 'fa-hand-holding-heart',
                                    'alimentação': 'fa-drumstick-bite',
                                    medicamento: 'fa-syringe'
                                }
                                return(<li key={'int_list_' + i}>
                                    <div>
                                    <i style={{fontSize: '1.3em'}} className={'fas ' + classIcons[e.categoria.toLowerCase()]}></i><span >{`${e.categoria.toUpperCase()} ${e.sub ? ' - ' + e.sub.toUpperCase() : ''}`}</span>
                                    </div>
                                    <div>
                                        <span>{`${new Date(e.data).toLocaleDateString('pt-BR',{ day: 'numeric', month: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric'})}`}</span>
                                    </div>
                                    <Link style={{color: 'var(--color-principal)'}} to={"/animal?tagid=" + e.receptorTag}>
                                        <i style={{fontSize: '1.3em'}} className="fas fa-info-circle"></i>
                                    </Link>
                                </li>)
                            })
                        }
                    </ul>
                </MyDialog>
            )
        }
        return null
    }

    render(){
        return(
            <React.Fragment>
                <Header historicDisplayFunction={this.toggleHistoric.bind(this)}/>
                <MySection distBottom="10vh" >
                    <MyContainer direction='row'>
                        <MyBtn classNames="soft-shadow" size="full" myStyle="solid" color="#FF4A3A" onClick={(e) => this.setRedirect(e, 'interacao')} >
                            <i className="fa fa-qrcode" ></i>INTERAÇÃO
                        </MyBtn> 
                        <MyBtn classNames="soft-shadow" size="small" myStyle="solid" color="#005999" onClick={(e) => this.setRedirect(e, 'cadastro')} >
                            <i className="fas fa-dog"></i><i className="fas fa-plus"></i>
                        </MyBtn>                   
                    </MyContainer>
                </MySection> 
                <MyMap newCenter={this.state.newCenter} firebase={this.state.firebase} logger={this.state.logger} />  
                {this.renderRedirect()}
                {this.renderDialog()}
            </React.Fragment>
        )
    }
}

export default Home