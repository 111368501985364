class Repository {
    constructor(){
        this.key = 'app-aujude'
    }

    save( json, callback){
        let data = JSON.stringify( json);
        window.localStorage.setItem( this.key, data);
        callback(json);
    }
    get( sucesso, falha){
        let data = window.localStorage.getItem( this.key);
        let obj = JSON.parse( data);
        if( obj)
            sucesso( obj);
        else
            falha();
    }
}

export default Repository;