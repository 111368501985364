import React from 'react'
import './index.css'

export default function MyDailog( props){
    let classes = props.classes ? `${props.classes} my-dialog` : 'my-dialog'
    return(
        <div className={classes} onClick={props.closeFunction}>
            <div className="my-dialog-card">
                <div className="my-dialog-header">
                    <span onClick={props.closeFunction}><i className="fas fa-times"></i></span>
                </div>
                <div className="my-dialog-content">
                    {props.children}
                </div>
            </div>
        </div>
    )
}