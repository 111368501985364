import React from 'react'
import { HashRouter, Switch, Route} from 'react-router-dom'
import Home from '../Home'
import PagInteracao from '../PagInteracao';
import PagCadastro from '../PagCadastro';
import PagHistorico from '../PagHistorico';
import PagEditProfile from '../PagEditProfile'
import QrModal from '../QrModal';
import MnModal from '../MnModal'
import PagPerfilAnimal from '../PagPerfilAnimal'

export default props =>{
    const usuario = props.usuario
    const firebase = props.firebase
    const message = props.toast
    const loading = props.loading
    const reloadHistoric = props.reloadHistoric

     return(<HashRouter>
        <Switch>
            <Route exact path="/" render={ (props) => <Home {...props.location.state} {...props} reloadHistoric={reloadHistoric} loading={loading} usuario={ usuario} firebase={ firebase} message={message} />} />
            <Route path="/interacao" render={ (props) => <PagInteracao {...props} reloadHistoric={reloadHistoric} loading={loading} usuario={ usuario} firebase={ firebase} message={message} />} />
            <Route path="/cadastro" render={ (props) => <PagCadastro {...props} reloadHistoric={reloadHistoric} loading={loading} usuario={ usuario} firebase={ firebase} message={message} />} />
            <Route path="/qrmodal" render={ (props) => <QrModal {...props} reloadHistoric={reloadHistoric} loading={loading} usuario={ usuario} firebase={ firebase} message={message} /> } />
            <Route path="/animal" render={ (props) => <PagPerfilAnimal {...props} reloadHistoric={reloadHistoric} loading={loading} usuario={ usuario} firebase={ firebase} message={message} /> } />
            <Route path="/historico" render={ (props) => <Home show="historic" {...props} reloadHistoric={reloadHistoric} loading={loading} usuario={ usuario} firebase={ firebase} message={message} />} />
            {/* <Route path="/profile" component={PagEditProfile} /> */}
            <Route path="/menu" component={MnModal} />
        </Switch>
    </HashRouter>)
}