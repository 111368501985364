import React from 'react'
import './index.css'
import { Link } from 'react-router-dom'

export default function Modal ( props){
    const renderLink = () => {
        if(! props.onClick){
            return(
                <Link to="/" ><i className="fas fa-times"></i></Link>  
            )
        }
        return null
    }

    return(
        <div className="modal">
            <div>
                <div className="modal-header">
                    <div></div>
                    <div className="modal-title">
                        <span>{props.title}</span>
                    </div>
                    <div className="modal-close">
                        { renderLink()} 
                    </div>
                </div>
                <div className="modal-content">
                    <div>
                        {props.children}
                    </div>
                </div>
            </div>
        </div>
    )
}