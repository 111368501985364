import React from 'react';
import { ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'

import './index.css'

class Toast extends React.Component{
    sucesso(msg) {
        toast.success(msg)
    }
    info(msg) {
        toast.info(msg)
    }
    infoWindow(msg) {
        toast(msg,{
            position: toast.POSITION.TOP_RIGHT,
            className: 'toast-infowindow'
        })
    }
    erro(msg) {
        toast.error(msg)
    }

    renderOther

    render() {
        return (
            <React.Fragment>
                <ToastContainer
                    position="bottom-center"
                    autoClose={5000}
                    hideProgressBar={true}
                    closeOnClick
                    pauseOnHover
                />
            </React.Fragment>
        );
    }
}

export default Toast