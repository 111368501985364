import NomearAnimal from './NomearAnimal.js'

class Animal{
    constructor(){
        //dados de auditoria, como data e autor de cadastro
        //ficarão na tag unica ligada ao anima
        this.tagId = '';
        this.id = '';
        this.nome = '';
        this.sexo = '';
        this.porte = '';
        this.raca = '';
        this.idade = '';
        this.fotos = [];

        this.vinculos = {
            conhecidos:[],
            amigos:[],
            padrinhos:[],
            guardioes:[],
            tutores:[],
        };
        this.historicoInteracao = [];
    }

    static buildAnimal( objGeneric){
        let animal = new Animal();

        Object.keys( animal).forEach( e => {
            animal[e] = objGeneric[e] || null
        })
        
        return animal
    }
}

export default Animal;