import React from 'react'
import './index.css'

export default function InputCheck( props){
    let onChange = props.onChange || null
    let isRequired = props.required || false
    let defaultValue = props.defaultValue || ''
    let isReadOnly = props.readOnly || false
    let type = props.type || 'text'
    let isChecked = props.hasChecked || false ;
    
    return(
        <div className="field-input input-check" >
            <label htmlFor={props.id} className="">{props.labelText}</label>
            <input 
                id={props.id} 
                name={props.name} 
                type={type}
                required={isRequired}
                defaultValue={defaultValue}
                onChange={onChange}
                readOnly={isReadOnly}
                checked={isChecked}
            />
        </div>
    )
}
