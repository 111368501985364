const reactionErrorGeneric = (er) => console.log(`error ao criar em...`,er)

class FireStore{
    constructor( firebase){
        this.fb = firebase;
    }

    consultar(col,id, callback,fail = reactionErrorGeneric){
        this.fb.firestore().collection(col).doc(id).get().then(callback).catch(fail);
    }
    salvar(col, id, data, callback, error){
        this.fb.firestore().collection(col).doc(id).set( Object.assign({}, data)).then(callback).catch(error);
    }
    criar(col, data, callback, fail = reactionErrorGeneric){
        this.fb.firestore().collection(col).add( Object.assign({}, data)).then(callback).catch(fail);
    }
    atualizar( col, id, data, callback, fail = reactionErrorGeneric){
        this.fb.firestore().collection( col).doc( id).update( data).then(callback).catch(fail);
    }
    async addWithIdInside(col, data, callback, fail = reactionErrorGeneric){
        try{
            let temp = await this.fb.firestore().collection(col).doc();
            data.id = temp.id
            
            if( data.fotos){
                data.fotos = await this.saveImage(data.id, data.fotos )
            }
            
            temp.set( Object.assign( {}, data))
            .then( () => callback(data))
        }catch(err){
            fail('Falha ao salvar interação')
        }
    }
    async addAnimal(userId, tagId, animal, callback, fail = reactionErrorGeneric){
        try{
            // em seguida a criação do animal a tag correspondente precisa ser atualizada
            const atualizaTag = ( objAnimal) => {
                let tagIdReturned = objAnimal.tagId
                if( tagIdReturned !== tagId) throw 'inconsistencia no valores de tagId'
                let time = new Date()
                let tagComSomenteAtributosAlterados = {
                    status: 'utilizada',
                    dataCadastro: time.getTime(),
                    idAnimal: objAnimal.id,
                    idCadastrante: userId
                }
                this.atualizar( 'tags', tagIdReturned, tagComSomenteAtributosAlterados, callback)
            }
            this.addWithIdInside('animais' , animal, atualizaTag)
        }catch(err){
            fail(err)
        }
    }
    async saveImage( id, images, fail = reactionErrorGeneric){
        let ref = this.fb.storage().ref(id)
        let result = []
        
        const sendOne = async () => {
            let image = images.shift();
            if( image){
                let indentificadorRandomico = Math.random().toFixed(3) * 1000;
                
                await ref.child(`${id}_${indentificadorRandomico}`).putString( image.split(',')[1],'base64',{contentType: 'image/png'})
                let url = await ref.child(`${id}_${indentificadorRandomico}`).getDownloadURL()
                result.push(url);
                await sendOne()
            }
        }
        try{
            await sendOne()
        }catch(err){
            fail(err)
        }
        return result;
    }

    async getInfoOfAnimalByTagId ( tagId){
        //debugger
        let snap = await this.fb.firestore().collection( 'animais').where( 'tagId', '==', tagId).get()
        
        if( snap.size){
            return snap.docs[0].data()
        } else {
            throw 'tag não vinculada à animal'
        }
    }
    async addInteraction(interaction, callback, fail = reactionErrorGeneric){
        try{
            this.addWithIdInside('interacoes' , interaction, callback, fail)
        }catch(err){
            fail('Falha ao salvar interação')
        }
    }

    async getPositionAnimais( coords){
        const getCodPosition = ( { lat, lng}) => {
            let latSimplified =  Math.abs( lat.toFixed(2)) * 100
            let lngSimplified =  Math.abs( lng.toFixed(2)) * 100
            
            let arrTempLatIncrement = [-1,-1,-1,0,0,1,1,1]
            let arrTempLngIncrement = [-1,0,1,-1,1,-1,0,1]
            
            let arrResult = [`${latSimplified}${lngSimplified}`]
            
            for( let i = 0; i < 8; i++){
                let codPositionIncremented = `${latSimplified + arrTempLatIncrement[i]}${lngSimplified + arrTempLngIncrement[i]}`
                arrResult.push( codPositionIncremented)
            }
            
            return arrResult
        }

        let arrCodLocation = getCodPosition( coords)
        let intermediaryResult = []

        // let p1 = this.fb.firestore().collection('animaisPosition').where( 'codLocation', '==', arrCodLocation[0]).get()
        // let p2 = this.fb.firestore().collection('animaisPosition').where( 'codLocation', '==', arrCodLocation[1]).get()
        // let p3 = this.fb.firestore().collection('animaisPosition').where( 'codLocation', '==', arrCodLocation[2]).get()

        // let p4 = this.fb.firestore().collection('animaisPosition').where( 'codLocation', '==', arrCodLocation[3]).get()
        // let p5 = this.fb.firestore().collection('animaisPosition').where( 'codLocation', '==', arrCodLocation[4]).get()
        // let p6 = this.fb.firestore().collection('animaisPosition').where( 'codLocation', '==', arrCodLocation[5]).get()

        // let p7 = this.fb.firestore().collection('animaisPosition').where( 'codLocation', '==', arrCodLocation[6]).get()
        // let p8 = this.fb.firestore().collection('animaisPosition').where( 'codLocation', '==', arrCodLocation[7]).get()
        // let p9 = this.fb.firestore().collection('animaisPosition').where( 'codLocation', '==', arrCodLocation[8]).get()

        // let resultQueries = await Promise.all([ p1, p2, p3, p4, p5, p6, p7, p8, p9])
        // intermediaryResult =  resultQueries.reduce( ( a, e) => {
        //     if( e.size){
        //         a.push( ...e.docs.map( e1 => e1.data()))
        //     } 
        //     return a
        // },[])
  	

        //alteracao para testes 26-01-2010 - codigo comentario acima tabem está realcionado a essa medida de testes
	    await this.fb.firestore().collection('animaisPosition').get()
	       .then( e => e.docs.map( e1 => intermediaryResult.push( e1.data()))) 
	    //fim alteracao para teste
	return intermediaryResult

    }
}

export default FireStore;
