import React from 'react'
import './index.css'

import {Map, Marker, GoogleApiWrapper} from 'google-maps-react';
import {UserPosition} from '../../services/UserPosition'

import FireStore from '../../services/FireStore';

import MyDialog from '../MyDialog'
import { Link } from 'react-router-dom'
import Animal from '../../model/Animal';

import icon_0_1 from './icon/icon_maker_red_1.svg'
import icon_0_2 from './icon/icon_maker_red_2.svg'
import icon_0_3 from './icon/icon_maker_red_3.svg'

import icon_1_1 from './icon/icon_1_1.svg'
import icon_1_2 from './icon/icon_1_2.svg'
import icon_1_3 from './icon/icon_1_3.svg'

import icon_2_1 from './icon/icon_2_1.svg'
import icon_2_2 from './icon/icon_2_2.svg'
import icon_2_3 from './icon/icon_2_3.svg'

import icon_3_1 from './icon/icon_3_1.svg'
import icon_3_2 from './icon/icon_3_2.svg'
import icon_3_3 from './icon/icon_3_3.svg'

const mapStyle = [
  {
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#f5f5f5"
      }
    ]
  },
  {
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#616161"
      }
    ]
  },
  {
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#f5f5f5"
      }
    ]
  },
  {
    "featureType": "administrative.land_parcel",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#bdbdbd"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#eeeeee"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#e5e5e5"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#ffffff"
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#dadada"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#616161"
      }
    ]
  },
  {
    "featureType": "road.local",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  },
  {
    "featureType": "transit.line",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#e5e5e5"
      }
    ]
  },
  {
    "featureType": "transit.station",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#eeeeee"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#c9c9c9"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  }
]

 
export class MyMap extends React.Component {
  constructor( props){
    super( props)

    this.state = {
      firebase: this.props.firebase,

      store: new FireStore( this.props.firebase),
      logger: this.props.logger,

      animal: new Animal(),

      initialCenter: props.initialCenter || JSON.parse(window.localStorage.getItem('initialCenter')) || {
        lat: -23.506,
        lng: -46.203
      },

      showHistoryInteraction: false,

      makers: [],
      showDialog: false,

      floatBtnMessage: '',
      floatBtnAction: null
    }
  }
  
  componentWillMount(){
    this.getUserLocation()
  }
  
  async getMakers( coordsRef){
    const store =  this.state.store
    
    let arrMakers = await store.getPositionAnimais( coordsRef)
    
    this.setState( { makers: arrMakers})
  }

  async getUserLocation(){
    const { latitude, longitude, timestamp, accuracy, error } = await UserPosition( true, 5000, 10000)
    
    const cord = {
      lat: latitude,
      lng: longitude
    }
    
    if(! this.state.initialCenter){
      this.setState({ initialCenter: cord})
    }
    window.localStorage.setItem( 'initialCenter', JSON.stringify(cord))
    
    this.getMakers( cord)
  }
  
  onMarkerClick = (props, marker, e) => {
    let store = this.state.store
    let idAnimal = props.animal
    let flagIsHistoric = props.isHistoric
    let interactionInfo = props.interactionInfo
    const fail = () => this.state.logger( 'erro', 'Falha ao recuperar dados do animal')
    const success = ( snap => {
      if( snap.exists){
        this.setState({ 
          animal: Animal.buildAnimal( snap.data()),
          showDialog: true
          //showHistoryInteraction: true
          
        })
      } else{
        throw 'animal não encontrado'
      }
    })
    
    if( flagIsHistoric){
      this.state.logger( 'infoWindow', `${interactionInfo.categoria} - ${interactionInfo.sub ? interactionInfo.sub + ' - ' : ''} ${new Date(interactionInfo.data).toLocaleDateString('pt-BR',{ day: 'numeric', month: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric'})}`)
    } else{
      store.consultar( 'animais', idAnimal,success,fail)
    }
    
  }
  
  successToSend(){
    this.setState({ isInvalid: true})
    this.state.logger( 'sucesso', 'sucesso no cadastro!')
  }
  
  onClose = props => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null
      });
    }
  };
  
  toggleShowDialog(e){
    let flagAtualizada = ! this.state.showDialog
    this.setState({showDialog: flagAtualizada})
  }
  
  toggleHistoryInMap( ){
    const exitHistoryIteracton = () => {
      this.setState({ 
        showHistoryInteraction: false,
        floatBtnMessage: ''
      })
    }
    this.setState({
      showHistoryInteraction: true,
      showDialog: false,
      floatBtnMessage: 'VOLTAR: ver animais no mapa',
      floatBtnAction: exitHistoryIteracton
    })
  }

  getIcon( date, type = 'default'){
    const less15daysAgo = (new Date()).getTime() - (1000*60*60*24*15)
    const less30daysAgo = (new Date()).getTime() - (1000*60*60*24*30)
    let code = ''
    let icon = null
    if( date > less15daysAgo) code += '1'
    else if( date > less30daysAgo) code += '2'
    else code += '3'

    if( type === 'default'){
      switch (code) {
        case '1':
          return icon_0_1
        case '2':
          return icon_0_2
        default:
          return icon_0_3
      }
    } else {
      if( type === 'alimentação') code += '2'
      else if( type === 'medicamento') code += '3'
      else code += '1'
      switch (code) {
        case '11':
          return icon_1_1
        case '12':
          return icon_1_2
        case '13':
          return icon_1_3
        case '21':
          return icon_2_1
        case '22':
          return icon_2_2
        case '23':
          return icon_2_3
        case '31':
          return icon_3_1
        case '32':
          return icon_3_2
        case '33':
          return icon_3_3
      }
    }
  }
  
  renderDialog(){
    const tagId = this.state.animal.tagId 

    if( this.state.showDialog){
      return(
        <MyDialog classes="menu-action-map-maker" closeFunction={this.toggleShowDialog.bind(this)}>
              <Link to={"/animal?tagid=" + tagId}>
                <i className="fas fa-dog" ></i><i className="fas fa-tags" ></i>
              </Link>
              <button onClick={this.toggleHistoryInMap.bind(this)}>
                <i className="fas fa-map" ></i>
                <i className="fas fa-draw-polygon" ></i>
              </button>
          </MyDialog>
        )
      }
      return null
    }
    
    renderMarkers(){
      let flagShowInteracionOneAnimal = this.state.showHistoryInteraction
      if( flagShowInteracionOneAnimal){
        return this.state.animal.historicoInteracao.map( ( e, i) => (
          <Marker 
          key={i}
          onClick={this.onMarkerClick}
          animal={e.receptorId}
          position={e.localizacao}
          isHistoric={true}
          interactionInfo={e}
          icon={{
            url: this.getIcon( e.data, e.categoria)
          }}
          />)
          )
      } 
        return this.state.makers.map( ( e, i) => (
          <Marker 
          key={i}
          onClick={this.onMarkerClick}
          animal={e.receptorId}
          position={e.localizacao}
          isHistoric={false}
          icon={{
            url: this.getIcon(e.data)
          }}
          />)
          )
    }
        renderFloatBtn(){
          const message = this.state.floatBtnMessage
          const action = this.state.floatBtnAction
          //debugger
          if( message && action){
            return(
              <div className="my-float-btn">
          <span onClick={action.bind(this)}>{this.state.floatBtnMessage}</span>
        </div>
      )
    }
    return null
  }
  
  _mapLoaded( mapProps, map){
    map.setOptions({
      styles: mapStyle
    })
  }
  
  render() {
    return (
      <React.Fragment>
        <Map 
          google={this.props.google} 
          zoom={14}
          initialCenter={this.state.initialCenter}
          center={this.props.newCenter}
          onReady={ ( mapProps, map) => this._mapLoaded( mapProps, map)}
          panControl={false}
          zoomControl={false}
          mapTypeControl={false}
          scaleControl={false}
          streetViewControl={false}
          overviewMapControl={false}
          rotateControl={false}
          fullscreenControl={false}
          >
          {this.renderMarkers()}
        </Map>
        {this.renderDialog()}
        {this.renderFloatBtn()}
      </React.Fragment>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyCGZ-z8rEwnLIetppUcE_8zgqNVhdNxYec'
})(MyMap)