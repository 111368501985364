import React from 'react'
import MySection from '../MySection'
import MyContainer from '../MyContainer'
import CardInteracao from '../CardInteracao'
import TitleOfGroup from '../TitleOfGroup'

const testeImg = [ './img/totozineo_1.jpg','./img/totozineo_2.jpg','./img/totozineo_2.jpg','./img/totozineo_2.jpg']

class PagHistorico extends React.Component{

    render(){
        return (
            <div>
               
                <MySection distTop="10vh">
                    <MyContainer >
                        <TitleOfGroup color="#005999" contentText="Edit Perfil" />
                        <div style={{height: '60vh', overflow: 'scroll'}}>
                            <CardInteracao imgUrl={testeImg[0]} />
                            <CardInteracao imgUrl={testeImg[0]} />
                            <CardInteracao imgUrl={testeImg[0]} />
                            <CardInteracao imgUrl={testeImg[0]} />
                            <CardInteracao imgUrl={testeImg[0]} />
                            <CardInteracao imgUrl={testeImg[0]} />
                            <CardInteracao imgUrl={testeImg[0]} />
                            <CardInteracao imgUrl={testeImg[0]} />
                            <CardInteracao imgUrl={testeImg[0]} />
                            <CardInteracao imgUrl={testeImg[0]} />
                        </div>
                    </MyContainer>
                </MySection>
            </div>
        )
    }
}

export default PagHistorico