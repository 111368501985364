import React from 'react'
import './index.css'

export default function InputText( props){
    
    const animaLabel = (e) => {
        //debugger
        let label = e.target.parentElement.firstElementChild
        label.classList.add('ativo');
        let input = e.target.parentElement.lastElementChild;
        input.addEventListener('blur', () => {
            if( input.value === ''){
                label.classList.remove('ativo');
            }
        });
    }
    let onChange = props.onChange || null
    let isRequired = props.required || false
    let defaultValue = props.defaultValue || ''
    return(
        <div className="field-input" >
            <label htmlFor={props.id} className="">{props.labelText}</label>
            <input 
                id={props.id} 
                name={props.name} 
                type={props.type} 
                required={isRequired}
                onFocus={animaLabel}
                defaultValue={defaultValue}
                onChange={onChange}
            />
        </div>
    )
}