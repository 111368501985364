import React, { Component } from 'react';
//login
import NovoUsuario from './components/NovoUsuario'

//model
import Cachorreiro from './model/Cachorreiro.js'
import Usuario from './model/Usuario.js'
//firebase
import {firebaseImpl} from './utils/firebaseUtils.js'
import FireAuth from './services/FireAuth.js'
import FireStore from './services/FireStore'
//router
import Routes from './components/Routes'

import Toast from './terceiros/Toast'

class App extends Component {
  constructor(){
    super()
    Cachorreiro.get( ca => {
      this.state = {
        usuario: ca,
        firebase: firebaseImpl,
        isLoading: false
      }
    }, () => {
      this.state = {
        usuario: undefined,
        firebase: firebaseImpl,
        isLoading: false
      }
    })
  }

  login = async (e, type) => {
    e.preventDefault();
    //obj de autenticação
    let auth = new FireAuth(this.state.firebase);

    //obj de acesso ao banco
    let store = new FireStore(this.state.firebase);

    //resolve probrema do escopo do this
    const atualizaState = (data) => this.setState({
      usuario: data,
    });
    this.setState({ isLoading: true })
    try{
      await auth[type]((dt) => {
        const id = dt.user.uid;
        store.consultar('cachorreiros',id, snapshot => {
          let uN ;
          let em ;
          let usuario = new Usuario();            //indiferente da preexistencia deve ser criado
  
          if( snapshot.exists){
            let dt = snapshot.data();
            uN = dt.userName;
            em = dt.mainEmail;
            let ca = new Cachorreiro(id, uN, em );
            ca.mainTelefone = dt.mainTelefone;
            ca.numTelefones = dt.numTelefones;
            ca.emails = dt.emails;
            ca.vinculos = dt.vinculos;
            ca.historicoInteracao = dt.historicoInteracao;
            //ca.animaisTutoriados = dt.animaisTutoriados;
  
            //recupera o perfil do usuario, se existir, senão o proprio obj
            //Usuario seta o perfil como padrão
            store.consultar('usuarios', id, snapshot => {
              if( snapshot.exists){
                let us = snapshot.data();
                 usuario.perfil = us.perfil;
              } 
  
              ca.perfil = usuario.perfil;
              ca.save( atualizaState);
            });
            
            
          } else {
            //se não existe é necessario salvar,
            //usando o token do auth como id
            uN = dt.user.displayName;
            em = dt.user.email;
            let ca = new Cachorreiro(id, uN, em );
            ca.perfil = usuario.perfil;
  
            store.salvar('cachorreiros',id,ca,() => {
              ca.save(atualizaState);
            });
          }
        });
      });
    }catch(error){
      window.alert('Falha no login...')
      console.log(error)
    }finally{
      this.setState({ isLoading: false })
    }
  }

  loginGoogle = (e) => {
    this.login(e, 'loginGoogle')
  }

  loginFacebook = (e) => {
    this.login(e, 'loginFacebook')
  }

  reloadHistoric(){
    //obj de acesso ao banco
    let store = new FireStore(this.state.firebase);
    //resolve probrema do escopo do this
    const atualizaState = (data) => this.setState({usuario: data});
    const ca = this.state.usuario
    store.consultar('cachorreiros',ca.id, snapshot => {
      if( snapshot.exists){
        const hitoricoCurrent = snapshot.data().historicoInteracao;
          ca.historicoInteracao = hitoricoCurrent
          ca.save( atualizaState);
      } 
    });
  }

  redenderNovoUsuario(){
    let usuario = this.state.usuario
    if( ! usuario){
      return(
        <NovoUsuario login={{
          google: this.loginGoogle.bind(this),
          facebook: this.loginFacebook.bind(this)
        }} />
      )
    }
  }

  renderMain(){
    let usuario = this.state.usuario
    if( usuario){
      return(
        <React.Fragment>
          <Routes usuario={this.state.usuario} reloadHistoric={this.reloadHistoric.bind(this)} firebase={this.state.firebase} toast={this.showMessage.bind(this)} loading={this.showOrHideLoading.bind(this)} />
        </React.Fragment>
      )
    }
  }

  showMessage( type, content){
    switch( type){
      case 'sucesso':
        this.refs.toast.sucesso( content)
      break
      case 'erro':
        this.refs.toast.erro( content)
      break
      case 'info':
        this.refs.toast.info( content)
      break
      case 'infoWindow':
        this.refs.toast.infoWindow( content)
      break
      default:
        this.refs.toast.info( content)
      break
    }
  }

  showOrHideLoading(val){
    this.setState({
      isLoading: val
    })
  }

  renderLoading(){
    const { isLoading} = this.state

    if( isLoading){
      return (
        <div className="loading-container">
          <div className="fa-3x">
            <i className="fas fa-circle-notch fa-spin"></i>
          </div>
        </div>
      )
    }
    return null
  }

  render() {
    return (
      <div className="App">
          {this.redenderNovoUsuario()}
          {this.renderMain()}
          {this.renderLoading()}
          <Toast ref="toast" />
      </div>
    );
  }
}

export default App;

